
import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faSoundcloud, faTiktok } from '@fortawesome/free-brands-svg-icons';

export class Header extends Component {
    render() {
        const imagePath = require('../img/profile_picture_min_min.jpg');
        return (
            <div className='Header'>
                <img src={imagePath} alt='Profile Picture' />
                <h1>Pauli 403</h1>
                <div className='SocialBar'>
                    <a href="https://instagram.com/pauli.403">
                        <FontAwesomeIcon icon={faInstagram} className='fa-icon'/>
                    </a>
                    <a href="https://soundcloud.com/pauli403">
                        <FontAwesomeIcon icon={faSoundcloud} className='fa-icon'/>
                    </a>
                    <a href="https://www.tiktok.com/@.pauli.403">
                        <FontAwesomeIcon icon={faTiktok} className='fa-icon'/>
                    </a>
                </div>
            </div>
        )
    }
}

export default Header
