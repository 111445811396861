import React, { Component } from 'react'
import Header from './Header'
import LinkCarousel from './LinkCarousel'
export class Wrapper extends Component {


    render() {
        return (
            <div className="Wrapper Route">
                <Header/>
                <LinkCarousel/>
                
            </div>
        )
    }
}

export default Wrapper
