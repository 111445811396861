import React from 'react';
import { useEffect } from 'react';
import './App.css';
import Wrapper from './components/wrapper';
import { BrowserRouter as Router, Routes, Route, Link, createBrowserRouter, RouterProvider, } from 'react-router-dom';
import Imprint from './components/Imprint';
import ErrorPage from './components/ErrorPage';
import Post, { loader as postLoader } from './components/Post';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Wrapper />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/imprint",
    element: <Imprint />,
  },
  {
    path: "/post/:id",
    element: <Post />,
    loader: postLoader
  }
]);


function App() {
  return (
    <div className="App" >
      <div className='background'></div>
      <div className='backdrop'></div>
      <div className='routerWrapper'>
        <RouterProvider router={router} />
        <div className='Footer'>
          
          <p><a href='/imprint'>Imprint</a> | This site does not use any cookies.</p>
        </div>
      </div>  
    </div>
  );
}

export default App;
