import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import BackLink from './BackLink'

export class Imprint extends Component {
    render() {
        return (
            <div className='Imprint Route center-flex'>
                <h2>Imprint</h2>
                <p>Diese Seite ist eine Privatwebsite und nach § 5 TMG nicht Impressumspflichtig. </p>
                <Link to='/'> Go Back</Link>
            </div>
        )
    }
}

export default Imprint
