
import React from 'react'
import { Link } from 'react-router-dom'

function ErrorPage() {
    return (
        <div className='Imprint Route center-flex'>
            <h2>404</h2>
            <p>This Page does not exist rip</p>
            <Link to='/'> Go Back</Link>
        </div>
    )
}
export default ErrorPage;