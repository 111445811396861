import axios from 'axios'
import { useEffect, useState, useRef} from 'react'
import React from 'react'
import { useSpring, animated } from '@react-spring/web'

function LinkCard(props) {
    const [featuredMediaURL, setfeaturedMediaURL] = useState('')
    const [isLoaded, setIsLoaded] = useState(true)
    const [showExcerpt, setShowExceprt] = useState(false);
    const ref = useRef(null);
    const { linkObject } = props;

    const excerpt = linkObject.excerpt.rendered;
    const [style, animate] = useSpring(() => ({ height: "0px"}), []);

    useEffect(() => {
        animate({
            height: (showExcerpt ? ref.current.offsetHeight : 0) + "px",
            // margin:(showExcerpt ? 20 : 0)+"px"
            
        });
    }, [animate, ref, showExcerpt]);

    useEffect(() => {
        if (linkObject.featured_media != 0) {
            axios.get(`/wordpress/wp-json/wp/v2/media/${linkObject.featured_media}`)
                .then(obj => {
                    setfeaturedMediaURL(obj.data.media_details.sizes.thumbnail.source_url)
                })
        }



        setIsLoaded(true)
    }, [])

    const onShowMore = (event) => {
        event.preventDefault();
        const newShowExcerpt = !showExcerpt
        setShowExceprt(newShowExcerpt);
    };
    if (isLoaded) {
        return (
            <div className={'LinkCard'} >
                <a href={linkObject.acf.link}>
                    <div className='mainLink'>
                        {featuredMediaURL != '' && (
                            <img src={featuredMediaURL} alt="Featured Media" />
                        )}
                        <div>
                        {excerpt != "" && (
                            <button className="show-more-button" onClick={onShowMore}>
                                {showExcerpt ? '-' : '+'}
                            </button>
                        )}
                        <h2 >{linkObject.title.rendered}</h2 >
                        </div>
                    </div>
                    {true && (
                        <>
                            <animated.div style={style}>
                                <div ref={ref} className="Excerpt"
                                    dangerouslySetInnerHTML={
                                        { __html: excerpt }
                                    }></div>
                            </animated.div>
                        </>
                    )}
                </a>
            </div>
        )
    } else {
        return (<div></div>)
    }

}
export default LinkCard;