import React, { Component } from 'react'
import LinkCard from './LinkCard'
import BigLinkCard from './BigLinkCard'
import axios from 'axios'


export class LinkCarousel extends Component {
    state = {
        links: [],
        isLoaded: false
    }
    componentDidMount() {
        axios.get('/wordpress/wp-json/wp/v2/links')
            .then(obj => {
                const links = obj.data;
                links.sort((a, b) => parseInt(a.acf.prio, 10) - parseInt(b.acf.prio, 10));
                this.setState(
                    {
                        links: links,
                        isLoaded: true
                    }
                )
            }
            )
            .catch(err => console.log(err));
    } 
    render() {
        const { links, isLoaded } = this.state
        if (isLoaded) {
            
            return (
                <div className='LinkCarousel'>
                    {links.map(link => {
                        if(parseInt(link.acf.prio, 10)==0){   
                            return <BigLinkCard key = {link.id} linkObject ={link} />
                        }else{
                            return <LinkCard key = {link.id} linkObject ={link} />
                        }
        })}
                </div>
            )
        }
    }
}

export default LinkCarousel
