import axios from 'axios'
import { useEffect, useState, useRef} from 'react'
import React from 'react'
import { useSpring, animated } from '@react-spring/web'

function BigLinkCard(props) {
    const [featuredMediaURL, setfeaturedMediaURL] = useState('')
    const [isLoaded, setIsLoaded] = useState(true)
    const ref = useRef(null);
    const { linkObject } = props;

    const excerpt = linkObject.excerpt.rendered;
    const [style, animate] = useSpring(() => ({ 
    height: "30px",
    config: {
        friction: 50,
        tension: 170,
      }
}), []);

    useEffect(() => {
        animate({
            height: (ref.current.offsetHeight) + "px",
        });
    }, [animate, ref]);

    useEffect(() => {
        if (linkObject.featured_media != 0) {
            axios.get(`/wordpress/wp-json/wp/v2/media/${linkObject.featured_media}`)
                .then(obj => {
                    setfeaturedMediaURL(obj.data.media_details.sizes.medium.source_url)
                })
        }



        setIsLoaded(true)
    }, [])

    if (isLoaded) {
        return (
            <>
            <div className={'LinkCard BigLinkCard'} >
                <animated.div style={style}>
                <div ref = {ref}>
                <a href={linkObject.acf.link}>
                    <div className='ImageContainer'>
                        <div></div>
                        {featuredMediaURL != '' && (
                            <img src={featuredMediaURL} alt="Featured Media" />
                        )}
                    </div>
                    <h2>{linkObject.title.rendered}</h2 >
                    <div >
                        <div className="Excerpt"
                            dangerouslySetInnerHTML={
                                { __html: excerpt }}>
                        </div>
                    </div>
                </a>
                </div>
                </animated.div>
            </div>
            </>
        )
    } else {
        return (<div></div>)
    }

}
export default BigLinkCard;