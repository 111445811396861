import axios from "axios";
import React, { useEffect, useState } from "react"
import { Link, useLoaderData } from "react-router-dom"
import SyncLoader from "react-spinners/SyncLoader";
import BackLink from "./BackLink";
export function loader({ params }) {
    const id = params.id;
    return id;
}
export default function Post() {
    const id = useLoaderData();
    const [isLoaded, setIsLoaded] = useState(false);
    const [post, setPost] = useState(null);
    const [featuredMediaURL, setFeaturedMediaURL] = useState("");
    const useFeaturedMedia = (featuredMediaURL != "");

    useEffect(() => {
        axios.get(`/wordpress/wp-json/wp/v2/posts/${id}`)
            .then((obj) => {
                const post = obj.data
                setPost(post);
                if (post.featured_media > 0) {
                    axios.get(`/wordpress/wp-json/wp/v2/media/${post.featured_media}`)
                        .then((e) => {
                            setFeaturedMediaURL(e.data.media_details.sizes.full.source_url);
                            const img = new Image();
                            img.onload = () => {
                                setIsLoaded(true)
                            }
                            img.src = e.data.media_details.sizes.full.source_url;
                        })
                        .catch((err) => console.log(err));

                } else {
                    setIsLoaded(true);
                }
            }
            )
            .catch((err) => console.log(err));
    }, [])
    if (isLoaded) {
        return (
            <div className="Post Route">
                <BackLink/>
                <h1>{post.title.rendered}</h1>
                {useFeaturedMedia && (<img src={featuredMediaURL}></img>)}
                <div dangerouslySetInnerHTML={
                    {__html:post.content.rendered}
                }></div>
            </div>
        )
    }
    else{
        return(
            <div className="Post Route">
            <SyncLoader
            color= "#CCCCCC"
            cssOverride={{
                margin: "65% auto",
                textAlign: "center"
              }}
            size="1.5em"
            margin="1em"
            />
            </div>
        )
    }
}

